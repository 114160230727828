<template>
  <div class="all-page">
    <div class="top-con">
      <img src="@static/vip/oil_and_person.png">
    </div>
    <div class="bg-con">
      <div class="text-con" @click="onSelect">
        <span>{{initTextPrice}}</span>
        <span><Icon name="arrow-down" style="padding-left: 10px"/></span>
      </div>
      <ActionSheet
        v-model="show"
        :actions="actions"
        cancel-text="取消"
        :round="true"
        @select="onSelect"
        @closed="closed"
      />
      <div class="oil-price-center">
        <div class="oil-line" v-for="(item, idx) in initOilPriceShow" :key="idx">
          <div class="left-text">{{item.name}}</div>
          <div class="right-color">
            <span class="oil-price-right">{{item.price}}</span>元/{{item.unit}}
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { ActionSheet, Icon } from 'vant';
import Creditshop from "@/api/creditshop";
export default {
  components: {
    ActionSheet, Icon
  },
  data() {
    return {
      show: false,
      shop_data: [],
      actions: [],
      now_idx: 0,
      initTextPrice: "",
      initOilPriceShow: []
    }
  },
  created() {
    this.getShopList()
  },
  methods: {
    onSelect(item, index) {
      this.show = !this.show;
      this.now_idx = index;
    },
    closed() {
      this.initOilPriceShow = this.actions[this.now_idx].goods;
      console.log(this.actions[this.now_idx].name)
      this.initTextPrice = this.actions[this.now_idx].name;
    },
    async getShopList() {
      const res = await Creditshop.shopList("", 1);
      if (res.code == 200) {
        for (let i in res.data) {
          let obj = {name: '', goods: []};
          obj.name = res.data[i].store_name;
          obj.goods = res.data[i].goods;
          this.actions.push(obj);
        }
        if (this.actions.length) {
          this.now_idx = 0;
          this.initTextPrice = this.actions[0].name;
          this.initOilPriceShow = this.actions[0].goods;
        }
        this.shop_data = res.data;
        // console.log(res.data)
      }
    }
  },

}
</script>

<style lang="scss" scoped>
.all-page {
  width: 100%;
  height: 100vh;
  background: #f4f4f4;
  .top-con {
    width: 100%;
    height: 230px;
    background: #999;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .text-con {
    height:30px;
    line-height:30px;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color:#fff;
    border-radius: 10px 0 10px 0;
    background-color: #646566;
    margin-top: 5px;
    margin-bottom: 10px;
  }
  .bg-con {
    width: 100%;
    min-height: 200px;
    overflow: hidden;
  }
  .oil-price-center {
    width: 100%;
    padding: 0 20px;

    .oil-line {
      width: 100%;
      display: flex;
      margin-top: 10px;
      font-size: 16px;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #dfdfdf;
      padding-bottom: 10px;
      .left-text {
        font-weight: 500;
      }
      .right-color {
        font-size: 12px;
        .oil-price-right {
          font-size: 16px;
          font-weight: 500;
          color: rgb(255, 128, 0);
          margin-right: 4px;
        }
      }
    }
  }
}

</style>